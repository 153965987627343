import { Outlet, Route, Routes, useLocation } from "react-router-dom";
// import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HomeClean from "./pages/Home/HomeClean";
import Shop from "./pages/Shop/Shop";
import ProductDetails from "./pages/Product-Details/ProductDetails";
import Cart from "./pages/Cart/Cart";
import Checkout from "./pages/Checkout/Checkout";
import Wishlist from "./pages/Wishlist/Wishlist";
import Blog from "./pages/Blog/Blog";
import BlogDetails from "./pages/Blog-Details/BlogDetails";
import AboutUs from "./pages/About-Us/AboutUs";
import Contact from "./pages/Contact/Contact";
import MyAccount from "./pages/My-Account/MyAccount";
import LoginRegister from "./pages/Login-Register/LoginRegister";
import AdminLogin from "./pages/auth/AdminLogin";
import ForgotPassword from "./pages/Forgot-Password/ForgotPassword";
import Page404 from "./pages/Page-404/Page404";
import ShopByHomeCategory from "./pages/ShopByHomeCategory/ShopByHomeCategory";
import Private from "./routes/private";
import AllUsers from "./pages/dashboard/AllUsers";
import Requests from "./pages/dashboard/Requests";
import OrderSummary from "./pages/dashboard/OrdersSummary";
import Subscribers from "./pages/dashboard/Subscribers";
import ContactUs from "./pages/dashboard/ContactUs";
import PolicyPages from "./pages/dashboard/PolicyPages";
import CreatePolicyPage from "./pages/dashboard/CreatePolicyPage";
import TryAtHome from "./pages/Try-At-Home/TryAtHome";
import EditPolicyPage from "./pages/dashboard/EditPolicyPage";
import Sliders from "./pages/dashboard/Sliders";
import Logo from "./pages/dashboard/logo";
import EditBrand from "./pages/dashboard/EditBrand";
import EditProduct from "./pages/dashboard/EditProduct";
import Categories from "./pages/dashboard/Categories";
import CreateCategory from "./pages/dashboard/CreateCategory";
import UpdateCategory from "./pages/dashboard/UpdateCategory";
import CreateProduct from "./pages/dashboard/CreateProduct";
import Brands from "./pages/dashboard/Brands";
import CreateBrand from "./pages/dashboard/CreateBrand";
import Orders from "./pages/dashboard/Orders";
import OrderDetails from "./pages/dashboard/OrderDetails";
import Products from "./pages/dashboard/Products";
import { useEffect } from "react";
import SearchOverlay from "./components/Search/Search";
import Policies from "./pages/Policies/Policies";
import SuccessPage from "./pages/Checkout/Success";

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <SearchOverlay/>
      <Footer />
    </>
  );
}

function App() {

  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  };

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomeClean />} />
          <Route path="/shop/:name/:id" element={<Shop />} />
          <Route path="/shopexclusive/:name" element={<ShopByHomeCategory />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="product/:_id" element={<ProductDetails />} />
          <Route path="cart" element={<Cart />} />
          <Route path="Checkout" element={<Checkout />} />
          <Route path="Wishlist" element={<Wishlist />} />
          <Route path="Blog" element={<Blog />} />
          <Route path="BlogDetails" element={<BlogDetails />} />
          <Route path="AboutUs" element={<AboutUs />} />
          <Route path="MyAccount" element={<MyAccount />} />
          <Route path="LoginRegister" element={<LoginRegister />} />
          <Route path="ForgotPassword" element={<ForgotPassword />} />
          <Route path="*" element={<Page404 />} />
          <Route path="payment-success" element={<SuccessPage />} />
          <Route path="try-at-home" element={<TryAtHome />} />
          <Route path="policies/:id" element={<Policies />} />
        </Route>
        <Route path="auth">
          <Route path="admin-login" element={<AdminLogin />} />
        </Route>
        <Route path="dashboard">
          <Route path="sliders" element={<Private><Sliders /></Private>} />
          <Route path="logos" element={<Private><Logo /></Private>} />
          <Route path="products" element={<Private><Products /></Private>} />
          <Route path="all_users" element={<Private><AllUsers /></Private>} />
          <Route path="products/:page" element={<Private><Products /></Private>} />
          <Route path="edit-product/:id" element={<Private><EditProduct /></Private>} />
          <Route path="categories" element={<Private><Categories /></Private>} />
          <Route path="categories/:page" element={<Private><Categories /></Private>} />
          <Route path="create-category" element={<Private><CreateCategory /></Private>} />
          <Route path="update-category/:id" element={<Private><UpdateCategory /></Private>} />
          <Route path="create-product" element={<Private><CreateProduct /></Private>} />
          <Route path="brands" element={<Private><Brands /></Private>} />
          <Route path="edit-schools/:id" element={<Private><EditBrand /></Private>} />
          <Route path="create-brand" element={<Private><CreateBrand /></Private>} />
          <Route path="orders" element={<Orders />} />
          <Route path="requests" element={<Requests />} />
          <Route path="subscribers" element={<Subscribers />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="orders/:page" element={<Orders />} />
          <Route path="order-details/:id" element={<OrderDetails />} />
          <Route path="order-summary/:id" element={<OrderSummary />} />
          <Route path="policypages" element={<PolicyPages />} />
          <Route path="createpolicypages" element={<CreatePolicyPage />} />
          <Route path="policypages/:id" element={<EditPolicyPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
