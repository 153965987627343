import React, { useEffect, useState } from "react";
import TrustSection from "../../components/TrustSection/TrustSection";
import ProductGallery from "../../components/ProductGallery/ProductGallery";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProductQuery } from "../../store/services/productService";
import currency from "currency-formatter";
import { addCart, addToWishlist } from "../../store/reducers/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import HTMLReactParser from "html-react-parser";
import toast from "react-hot-toast";
import { useCatProductsQuery, useGetPricingQuery } from "../../store/services/homeProducts";
import ProductsSwiper from "../../components/ProductsSwiper/ProductsSwiper";
import Loader from "../../components/Loader/Loader";
import LoginModal from "../../components/LoginModal/LoginModal";
import { useMediaQuery } from "react-responsive";
import { FaHome } from "react-icons/fa";
import LiveVideoCall from "../../components/LiveVideoCall";

const ProductDetails = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupBreakDown, setShowPopupBreakDown] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 640 });
  const isMediumScreen = useMediaQuery({ minWidth: 641, maxWidth: 1024 });
  const isLargeScreen = useMediaQuery({ minWidth: 1025 });
  const { _id } = useParams();
  const { data, isFetching } = useGetProductQuery(_id);
  const [selected_karat, setselected_karat] = useState(null)
  const [selected_size, setselected_size] = useState(null)
  const shouldFetchPricing = data?.multiplesize === true;
  const { data:pricingdata, isFetching:pricefetch } = useGetPricingQuery({productId:_id, size:selected_size?.size, karat:selected_karat?.karat});
  const { data: relatedproducts, isFetching: relatedproductloading } =
    useCatProductsQuery({ name: data?.category, page: 1 });
  const dispatch = useDispatch();
  const [quantity, setquantity] = useState(1);
  const navigate = useNavigate();
  let desc = data?.description ? HTMLReactParser(data?.description) : "";
  const { userToken, user } = useSelector((state) => state.authReducer);
  const [showlogin, setshowlogin] = useState(false);


  useEffect(() => {
    if(data){
setselected_karat(data?.metalDetails.karats[0])
    }
    if(data?.multiplesize){
      setselected_size(data?.sizes[0])
    }
  
  }, [data])
  

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const togglePopupBreakDown = () => {
    setShowPopupBreakDown(!showPopupBreakDown);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addToCart_apihit = () => {
    const { _id, name: title, images: image } = data || {}; // Extracting _id, title, and image
    const cart = localStorage.getItem("cart");
    const cartItems = cart ? JSON.parse(cart) : [];

    // Check if the product is already in the cart
    const checkItem = cartItems.find((item) => item._id === _id);

    if (!checkItem) {
      // Add _id, title, and image to the cart
      const newProduct = { _id, title, image: image[0], quantity,size:selected_size?.size,karat:selected_karat?.karat };
      dispatch(addCart(newProduct));
      cartItems.push(newProduct);
      toast.success(`${title} Added to cart`);
      localStorage.setItem("cart", JSON.stringify(cartItems));
    } else {
      toast.error(`${title} is already in cart`);
    }
  };
  const addWishlist = () => {
    const { _id, name: title, images: image } = data || {}; // Extracting _id, title, and image
    const wishlist = localStorage.getItem("wishlist");
    const wishlistItems = wishlist ? JSON.parse(wishlist) : [];

    // Check if the product is already in the wishlist
    const checkItem = wishlistItems.find((item) => item._id === _id);

    if (!checkItem) {
      // Add _id, title, and image to the wishlist
      const newProduct = { _id, title, image: image[0], quantity,size:selected_size?.size,karat:selected_karat?.karat };
      dispatch(addToWishlist(newProduct));
      wishlistItems.push(newProduct);
      toast.success(`${title} Added to Wishlist`);
      localStorage.setItem("wishlist", JSON.stringify(wishlistItems));
    } else {
      toast.error(`${title} is already in Wislist`);
    }
  };

  const buyitnow_apihit = () => {
    const { _id, name: title, images: image } = data || {}; // Extracting _id, title, and image
    const cart = localStorage.getItem("cart");
    const cartItems = cart ? JSON.parse(cart) : [];

    // Check if the product is already in the cart
    const checkItem = cartItems.find((item) => item._id === _id);

    if (!checkItem) {
      // Add _id, title, and image to the cart
      const newProduct = { _id, title, image: image[0], quantity };
      dispatch(addCart(newProduct));
      cartItems.push(newProduct);
      toast.success(`${title} Proceeding to Buy`);
      localStorage.setItem("cart", JSON.stringify(cartItems));
      if (userToken) {
        navigate("/Checkout");
      } else {
        setshowlogin(true);
      }
    } else {
      if (userToken) {
        navigate("/Checkout");
      } else {
        setshowlogin(true);
      }
    }
  };

  return (
    <>
      <LoginModal showModal={showlogin} setShowModal={setshowlogin} />
      <div id="page" className="hfeed page-wrapper">
        <div id="site-main" className="site-main">
          <div id="main-content" className="main-content">
            <div id="primary" className="content-area">
              <div id="title" className="page-title">
                <div className="section-container">
                  <div className="content-title-heading">
                    <h1 className="text-title-heading">{data?.categoryDetails?.name}</h1>
                  </div>
                  <div className="breadcrumbs">
                    <a href="index.html">Home</a>
                    <span className="delimiter" />
                    <a href="shop-grid-left.html">Shop</a>
                    <span className="delimiter" />
                    {data?.categoryDetails?.name}
                  </div>
                </div>
              </div>
              <div id="content" className="site-content" role="main">
                <div
                  className="shop-details zoom"
                  data-product_layout_thumb="scroll"
                  data-zoom_scroll="true"
                  data-zoom_contain_lens="true"
                  data-zoomtype="inner"
                  data-lenssize={200}
                  data-lensshape="square"
                  data-lensborder=""
                  data-bordersize={2}
                  data-bordercolour="#f9b61e"
                  data-popup="false"
                >
                  <div className="product-top-info">
                    <div className="section-padding">
                      <div className="section-container p-l-r">
                        <div className="row">
                          {/* <div className="product-images col-lg-7 col-md-12 col-12">
                        <div className="row"> */}

                          <ProductGallery imageData={data?.images} />

                          {/* </div>
                          </div> */}
                          {/* <div className="product-images col-lg-7 col-md-12 col-12">
                            <div className="row">
                              <div className="col-md-2">
                                <div className="content-thumbnail-scroll">
                                  <div
                                    className="image-thumbnail slick-carousel slick-vertical"
                                    data-asnavfor=".image-additional"
                                    data-centermode="true"
                                    data-focusonselect="true"
                                    data-columns4={5}
                                    data-columns3={4}
                                    data-columns2={4}
                                    data-columns1={4}
                                    data-columns={4}
                                    data-nav="true"
                                    data-vertical='"true"'
                                    data-verticalswiping='"true"'
                                  >
                                    <div className="img-item slick-slide">
                                      <span className="img-thumbnail-scroll">
                                        <img
                                          width={600}
                                          height={600}
                                          src="media/product/1.jpg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="img-item slick-slide">
                                      <span className="img-thumbnail-scroll">
                                        <img
                                          width={600}
                                          height={600}
                                          src="media/product/1-2.jpg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="img-item slick-slide">
                                      <span className="img-thumbnail-scroll">
                                        <img
                                          width={600}
                                          height={600}
                                          src="media/product/2.jpg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="img-item slick-slide">
                                      <span className="img-thumbnail-scroll">
                                        <img
                                          width={600}
                                          height={600}
                                          src="media/product/2-2.jpg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="img-item slick-slide">
                                      <span className="img-thumbnail-scroll">
                                        <img
                                          width={600}
                                          height={600}
                                          src="media/product/3.jpg"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-10">
                                <div className="scroll-image main-image">
                                  <div
                                    className="image-additional slick-carousel"
                                    data-asnavfor=".image-thumbnail"
                                    data-fade="true"
                                    data-columns4={1}
                                    data-columns3={1}
                                    data-columns2={1}
                                    data-columns1={1}
                                    data-columns={1}
                                    data-nav="true"
                                  >
                                    <div className="img-item slick-slide">
                                      <img
                                        width={900}
                                        height={900}
                                        src="media/product/1.jpg"
                                        alt=""
                                        title=""
                                      />
                                    </div>
                                    <div className="img-item slick-slide">
                                      <img
                                        width={900}
                                        height={900}
                                        src="media/product/1-2.jpg"
                                        alt=""
                                        title=""
                                      />
                                    </div>
                                    <div className="img-item slick-slide">
                                      <img
                                        width={900}
                                        height={900}
                                        src="media/product/2.jpg"
                                        alt=""
                                        title=""
                                      />
                                    </div>
                                    <div className="img-item slick-slide">
                                      <img
                                        width={900}
                                        height={900}
                                        src="media/product/2-2.jpg"
                                        alt=""
                                        title=""
                                      />
                                    </div>
                                    <div className="img-item slick-slide">
                                      <img
                                        width={900}
                                        height={900}
                                        src="media/product/3.jpg"
                                        alt=""
                                        title=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="product-info col-lg-5 col-md-12 col-12 ">
                            <h1 className="title"> {currency.format(pricingdata?.finalPrice || data?.pricing.finalPrice, {
                                    code: "INR",
                                  })}</h1>
                            <span className="price">
                              {data?.discount ? (
                                <del aria-hidden="true">
                                  <span>
                                    {currency.format(
                                      pricingdata?.finalPrice || data?.pricing.finalPrice + data.discount,
                                      { code: "INR" }
                                    )}
                                  </span>
                                </del>
                              ) : null}
                              {/* <ins>
                                <span>
                                  {" "}
                                  {currency.format(data?.pricing.finalPrice, {
                                    code: "INR",
                                  })}
                                </span>
                              </ins> */}
                            </span>
                            {/* <div className="rating">
                              <div className="star star-5" />
                              <div className="review-count">
                                (3<span> reviews</span>)
                              </div>
                            </div> */}

                            <div
                              className={
                                "flex justify-start items-center min-h-auto"
                              }
                            >
                              <button
                                onClick={togglePopupBreakDown}
                                className="bg-[#E5B53A] text-black text-xs font-semibold px-4 py-2 mr-6 rounded-full shadow-lg hover:bg-[#D1A534] transition-all duration-300 ease-in-out"
                              >
                                + PRICE BREAKUP
                              </button>
                              {showPopupBreakDown && (
                                <div
                                style={{zIndex:999999}}
                                  className={`fixed ${
                                    isSmallScreen
                                      ? "bottom-0 left-0 right-0 h-[90vh]"
                                      : isMediumScreen
                                      ? "top-0 right-0 h-full w-[70%]"
                                      : "top-0 right-0 h-full w-[450px]"
                                  } bg-white shadow-2xl transform transition-transform duration-300 ease-in-out ${
                                    isSmallScreen
                                      ? "rounded-t-2xl"
                                      : "rounded-l-2xl"
                                  } flex flex-col z-50`}
                                >
                                  <div className="p-6 border-b border-gray-200">
                                    <div className="flex justify-between items-center">
                                      <h2 className="text-xl font-bold text-gray-800">
                                        Price Breakdown
                                      </h2>
                                      <button
                                        onClick={togglePopupBreakDown}
                                        className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full p-1"
                                        aria-label="Close"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>

                                  <div className="flex-1 overflow-y-auto p-6">
                                    <PriceItem
                                      title={`Gold (${selected_karat?.karat} KT)`}
                                      subtitle={`${selected_size?.grossWeight || data?.metalDetails.grossWeight} grams @ ₹${selected_karat?.metalRate} / g`}
                                      price={`₹${selected_karat?.metalPrice}`}
                                    />
                                    <PriceItem
                                      title={`Diamond (IJ-SI)`}
                                      subtitle={data?.stoneDetails.diamond.carats}
                                      price={`₹${data?.stoneDetails.diamond.amount}`}
                                    />
                                    <PriceItem
                                      title="Making Charges"
                                      price={`₹${pricingdata?.labourAmount || data?.labourDetails.labourAmount}`}
                                    />

                                    <Subtotal
                                      label="Subtotal"
                                      amount={`₹${pricingdata?.totalPrice || data?.pricing.totalPrice}`}
                                    />
                                    <PriceItem title="Tax" price={`₹${pricingdata?.gst || data?.pricing.gst}`} />
                                    <Subtotal
                                      label="Grand Total"
                                      amount={`₹${pricingdata?.finalPrice || data?.pricing.finalPrice}`}
                                      isTotal
                                    />

                                    <DiamondBreakdown weight={data?.stoneDetails.diamond.carats} />
                                  </div>

                                  <div className="border-t border-gray-200 p-6"  onClick={(e) => {
                                      e.preventDefault(); // Prevents default link behavior
                                      console.log("ok ok ");
                                      addToCart_apihit(); // Calls your function
                                      togglePopupBreakDown()
                                    }}>
                                    <button className="w-full bg-[#E5B53A] text-black font-bold py-4 rounded-full text-lg uppercase hover:bg-[#D1A534] transition-all duration-300 ease-in-out shadow-lg">
                                      Add to Cart
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="productcontainer">
                              <section
                                id="pdpage-pddetails"
                                className="product-details"
                              >
                                <section className="details-content">
                                  <div className="product-description">
                                    <div
                                      id="skucontentdiv"
                                      className="description-text"
                                    >
                                      Set in {selected_karat?.karat} KT{" "}
                                      Gold (
                                      {selected_size?.grossWeight || data?.metalDetails.grossWeight} g) with
                                      diamonds (
                                      {data?.stoneDetails.diamond.carats} ct ){" "}
                                      <br />
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      "flex justify-center items-center min-h-auto"
                                    }
                                  >
                                    <div
                                      className={`flex ${
                                        isSmallScreen ? "w-full" : "w-[412px]"
                                      } h-[48px] cursor-pointer`}
                                      onClick={togglePopup}
                                    >
                                      <div className="flex-grow">
                                        <div className="flex h-full border border-gray-300 rounded-l-lg">
                                          {[ ...(data?.multiplesize ? ["Size"] : []), "Metal", "Diamond"].map(
                                            (item, index) => (
                                              <div
                                                key={item}
                                                className={`flex-1 p-1.5 ${
                                                  index !== 2 ? "border-r" : ""
                                                } border-gray-300 flex flex-col justify-center`}
                                              >
                                                <div className="text-[9px] text-gray-600">
                                                  {item}
                                                </div>
                                                <div className="text-[9px] font-medium">
                                                  {item === "Size"
                                                    ? `${selected_size?.size} (${selected_size?.name})`
                                                    : item === "Metal"
                                                    ? `${selected_karat?.karat} KT `
                                                    : "IJ-SI"}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                      <button className="bg-[#E5B53A] text-black text-xs font-medium px-3 py-1.5 rounded-r-lg hover:bg-[#D1A534] transition-colors duration-300">
                                        CUSTOMISE
                                      </button>
                                    </div>
                                    {showPopup && (
                                      <div
                                      style={{zIndex:999999}}
                                        className={`fixed ${
                                          isSmallScreen
                                            ? "bottom-0 left-0 right-0 h-[90vh]"
                                            : isMediumScreen
                                            ? "top-0 right-0 h-full w-[70%]"
                                            : "top-0 right-0 h-full w-[450px]"
                                        } bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
                                          isSmallScreen
                                            ? "rounded-t-xl"
                                            : "rounded-l-xl"
                                        } flex flex-col z-50`}
                                      >
                                        <div className="p-4 border-b">
                                          <div className="flex justify-between items-start">
                                            <div className="flex-1">
                                              <p className="text-xs text-gray-600 mb-1">
                                                Estimated price
                                              </p>
                                              <p className="text-lg font-semibold">
                                              {currency.format(pricingdata?.finalPrice || data?.pricing.finalPrice, {
                                    code: "INR",
                                  })}
                                              </p>
                                            </div>
                                            <div className="flex-1 text-right">
                                              <p className="text-xs text-gray-600 mb-1">
                                                Delivery within
                                              </p>
                                              <p className="text-sm font-medium">
                                                10 - 15 Days
                                              </p>
                                            </div>
                                            <button
                                              onClick={togglePopup}
                                              className="text-gray-500 hover:text-gray-700 transition-colors duration-200 ml-4 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full"
                                              aria-label="Close"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth={2}
                                                  d="M6 18L18 6M6 6l12 12"
                                                />
                                              </svg>
                                            </button>
                                          </div>
                                        </div>

                                        <div className="flex-1 overflow-y-auto p-3 md:p-5">
                                          {[
                                            "Choose Metal",
                                            "Diamond Quality",
                                          ].map((section, index) => (
                                            <div
                                              key={section}
                                              className="mb-5 md:mb-6"
                                            >
                                              <h3 className="text-sm font-semibold mb-2">
                                                {section}
                                              </h3>
                                              <div className="flex flex-wrap md:flex-nowrap space-y-2 md:space-y-0 md:space-x-3">
                                                {index === 0
                                                  ? data?.metalDetails.karats.map(
                                                      (karat) => (
                                                        <button
                                                          key={karat._id}
                                                          onClick={()=> setselected_karat(karat)}
                                                          className={`w-full md:w-auto px-10 py-1.5 border rounded-md text-xs font-medium ${
                                                            selected_karat?._id === karat?._id
                                                              ? "bg-[#F4F1FF] text-[#E5B53A]"
                                                              : ""
                                                          } hover:bg-gray-100 transition-colors duration-200`}
                                                        >
                                                          <div className="font-medium">
                                                            {karat.karat} KT
                                                          </div>
                                                          <div className="text-[9px]">
                                                            Gold
                                                          </div>
                                                          <div className="text-[8px] text-gray-500">
                                                            In Stock
                                                          </div>
                                                        </button>
                                                      )
                                                    )
                                                  : ["IJ-SI"].map(
                                                      (quality, idx) => (
                                                        <button
                                                          key={quality}
                                                          className={`w-full md:w-auto px-3 py-1.5 border rounded-md text-xs font-medium ${
                                                            idx === 0
                                                              ? "bg-[#F4F1FF] text-[#E5B53A]"
                                                              : ""
                                                          } hover:bg-gray-100 transition-colors duration-200`}
                                                        >
                                                          <div className="font-medium">
                                                            {quality}
                                                          </div>
                                                          <div className="text-[8px] text-gray-500">
                                                            {idx === 0
                                                              ? "Standard"
                                                              : "Premium"}
                                                          </div>
                                                        </button>
                                                      )
                                                    )}
                                              </div>
                                            </div>
                                          ))}
                                       {data?.multiplesize ?   <div className="mb-5 md:mb-6">
                                            <div className="flex justify-between items-center mb-2">
                                              <h3 className="text-sm font-semibold">
                                                Select Size
                                              </h3>
                                              <span onClick={() => navigate("/Contact")} className="text-xs text-[#E5B53A] underline cursor-pointer hover:text-[#D1A534] transition-colors duration-200">
                                                Size Guide
                                              </span>
                                            </div>
                                            <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-2">
                                              {data?.sizes.map((size) => (
                                                <button
                                                onClick={()=> setselected_size(size)}
                                                  key={size?._id}
                                                  className={`px-1.5 md:px-2 py-1.5 border rounded-md text-xs font-medium ${
                                                    size?._id === selected_size?._id
                                                      ? "bg-[#F4F1FF] text-[#E5B53A] border-[#E5B53A]"
                                                      : "border-gray-300 hover:border-[#E5B53A]"
                                                  } transition-colors duration-200 flex flex-col items-center justify-center`}
                                                >
                                                  <div className="text-sm md:text-base font-medium">
                                                    {size?.size}
                                                  </div>
                                                  <div className="text-[8px] md:text-[9px] text-gray-500">
                                                    {size?.name}
                                                  </div>
                                                  <div className="text-[7px] md:text-[8px] text-gray-500 mt-0.5">
                                                    Made to Order
                                                  </div>
                                                </button>
                                              ))}
                                            </div>
                                          </div>:null}
                                        </div>

                                        <div className="border-t pt-4 md:pt-6">
                                          <button onClick={togglePopup} className="w-full bg-[#E5B53A] text-black font-semibold py-3 md:py-4 rounded-bl-lg rounded-br-lg text-sm md:text-base uppercase hover:bg-[#D1A534] transition-colors duration-200 shadow-md">
                                            Confirm Customisation
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div className="details-boxes">
                                    <div className="details-box gold">
                                      <div className="box-header">
                                        <span className="icon gold-icon"></span>
                                        <p className="box-title">
                                          {data?.metalDetails.type}
                                        </p>
                                      </div>
                                      <div className="product-detail-meta">
                                        <span className="sku-wrapper">
                                          Net:{" "}
                                          <span className="sku">
                                            {selected_size?.netWeight || data?.metalDetails.netWeight}{" "}
                                            gram
                                          </span>
                                        </span>
                                        <span className="sku-wrapper">
                                          Gross:{" "}
                                          <span className="sku">
                                            {selected_size?.grossWeight || data?.metalDetails.grossWeight}{" "}
                                            gram
                                          </span>
                                        </span>
                                        <span className="posted-in">
                                          Purity:{" "}
                                          <a
                                            href="shop-grid-left.html"
                                            rel="tag"
                                          >
                                            {selected_karat?.karat} kt
                                          </a>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="details-box diamond">
                                      <div className="box-header">
                                        <span className="icon diamond-icon"></span>
                                        <p className="box-title">Diamond</p>
                                      </div>
                                      <div className="box-content">
                                        <div className="product-detail-meta">
                                          <span className="sku-wrapper">
                                            Quality:{" "}
                                            <span className="sku">
                                           
                                              IJ-SI
                                            </span>
                                          </span>
                                          <span className="posted-in">
                                            Total Weight:{" "}
                                            <a
                                              href="shop-grid-left.html"
                                              rel="tag"
                                            >
                                              {
                                                data?.stoneDetails.diamond
                                                  .carats
                                              }{" "}
                                              ct
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </section>
                            </div>
                            {/* <div className="variations">
                              <table cellSpacing={0}>
                                <tbody>
                                  <tr>
                                    <td className="label">Size</td>
                                    <td className="attributes">
                                      <ul className="text">
                                        <li>
                                          <span>L</span>
                                        </li>
                                        <li>
                                          <span>M</span>
                                        </li>
                                        <li>
                                          <span>S</span>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="label">Color</td>
                                    <td className="attributes">
                                      <ul className="colors">
                                        <li>
                                          <span className="color-1" />
                                        </li>
                                        <li>
                                          <span className="color-2" />
                                        </li>
                                        <li>
                                          <span className="color-3" />
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div> */}
                            <div className="buttons">
                              <div className="add-to-cart-wrap">
                                <div className="quantity">
                                  <button
                                    type="button"
                                    onClick={() => setquantity(quantity + 1)}
                                    className="plus"
                                  >
                                    +
                                  </button>
                                  <input
                                    type="number"
                                    className="qty"
                                    step={1}
                                    min={0}
                                    max=""
                                    name="quantity"
                                    defaultValue={1}
                                    title="Qty"
                                    size={4}
                                    placeholder=""
                                    value={quantity}
                                    inputMode="numeric"
                                    autoComplete="off"
                                  />
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setquantity(
                                        quantity == 1 ? 1 : quantity - 1
                                      )
                                    }
                                    className="minus"
                                  >
                                    -
                                  </button>
                                </div>
                                <div className="btn-add-to-cart">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevents default link behavior
                                      console.log("ok ok ");
                                      addToCart_apihit(); // Calls your function
                                    }}
                                    tabIndex={0}
                                  >
                                    Add to cart
                                  </a>
                                </div>
                              </div>
                              <div
                                className="btn-quick-buy"
                                data-title="Wishlist"
                                onClick={() => buyitnow_apihit()}
                              >
                                <button className="product-btn">
                                  Buy It Now
                                </button>
                              </div>

<div className="flex flex-row">
                              <div className="flex flex-col items-start p-4 bg-white w-half">
                                <div className="flex items-center mb-2">
                                  <span className="text-[#E5B53A] mr-2">
                                    <FaHome />
                                  </span>
                                  <span className="text-gray-800 font-medium">
                                    Try At Home
                                  </span>
                                  <span className="text-[#E5B53A] ml-1 text-sm">
                                    (It's Free)
                                  </span>
                                </div>
                                <div className="text-sm text-gray-600 mb-3">
                                  Home Appointment Available to try from
                                  Tomorrow!
                                </div>
                                <button 
                                  className="w-full bg-[#E5B53A] text-black py-2 px-4 rounded-full text-sm font-medium hover:bg-[#E5B53A] transition-colors duration-300"
                                  onClick={() => navigate('/try-at-home')}
                                >
                                  BOOK HOME APPOINTMENT
                                </button>
                              </div>

                              <LiveVideoCall productId={_id}/>
                              </div>
                              <div
                                className="btn-wishlist"
                                data-title="Wishlist"
                              >
                                <button className="product-btn" onClick={() => addWishlist()}>
                                  Add to wishlist
                                </button>
                              </div>
                              {/* <div className="btn-compare" data-title="Compare">
                                <button className="product-btn">Compare</button>
                              </div> */}
                            </div>
                            <div className="product-meta">
                              <span className="sku-wrapper">
                                SKU: <span className="sku">{data?.name}</span>
                              </span>
                              <span className="posted-in">
                                Category:{" "}
                                <a href="shop-grid-left.html" rel="tag">
                                  Bracelets
                                </a>
                              </span>
                              <span className="tagged-as">
                                Tags:{" "}
                                <a href="shop-grid-left.html" rel="tag">
                                  Hot
                                </a>
                                ,{" "}
                                <a href="shop-grid-left.html" rel="tag">
                                  Trend
                                </a>
                              </span>
                            </div>
                            {/* <div className="social-share">
                              <a
                                href="#"
                                title="Facebook"
                                className="share-facebook"
                                target="_blank"
                              >
                                <i className="fa fa-facebook" />
                                Facebook
                              </a>
                              <a
                                href="#"
                                title="Twitter"
                                className="share-twitter"
                              >
                                <i className="fa fa-twitter" />
                                Twitter
                              </a>
                              <a
                                href="#"
                                title="Pinterest"
                                className="share-pinterest"
                              >
                                <i className="fa fa-pinterest" />
                                Pinterest
                              </a>
                            </div> */}
                            <TrustSection />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              
                  {relatedproducts?.products.filter(
                    (item) => item._id !== data._id
                  ).length > 0 ? (
                    <div className="product-related">
                      <div className="section-padding">
                        <div className="section-container p-l-r">
                          <div className="block block-products slider">
                            <div className="block-title">
                              <h2>Related Products</h2>
                            </div>
                            <div className="block-content">
                              <ProductsSwiper
                                data={relatedproducts?.products.filter(
                                  (item) => item._id !== data._id
                                )}
                                isFetching={relatedproductloading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* #content */}
            </div>
            {/* #primary */}
          </div>
          {/* #main-content */}
        </div>
      </div>
      {/* Back Top button */}
      {/* <div className="back-top button-show">
        <i className="arrow_carrot-up" />
      </div> */}

      <Loader isFetching={isFetching} />
      {/* Page Loader */}
      {/* <div className="page-preloader">
    <div className="loader">
      <div />
      <div />
    </div>
  </div> */}
      {/* Dependency Scripts */}
      {/* Site Scripts */}
    </>
  );
};

const PriceItem = ({ title, subtitle, price }) => (
  <div className="mb-6">
    <h3 className="text-sm font-semibold text-gray-800 mb-2">{title}</h3>
    <div className="flex justify-between items-center">
      {subtitle && <span className="text-xs text-gray-600">{subtitle}</span>}
      <span className="text-sm font-medium text-gray-800">  {currency.format(price, {
                                    code: "INR",
                                  })}</span>
    </div>
  </div>
);

const Subtotal = ({ label, amount, isTotal }) => (
  <div
    className={`border-t border-gray-200 pt-4 mb-6 ${isTotal ? "mt-6" : ""}`}
  >
    <div className="flex justify-between items-center">
      <span
        className={`${
          isTotal ? "text-lg" : "text-sm"
        } font-semibold text-gray-800`}
      >
        {label}
      </span>
      <span
        className={`${isTotal ? "text-xl" : "text-lg"} font-bold text-gray-800`}
      >
         {currency.format(amount, {
                                    code: "INR",
                                  })}
        
      </span>
    </div>
  </div>
);

const DiamondBreakdown = ({weight}) => (
  <div className="mb-6 bg-[#F4F1FF] p-4 rounded-lg">
    <h3 className="text-sm font-semibold text-gray-800 mb-3">
      DIAMOND PRICE BREAKUP
    </h3>
    <div className="grid grid-cols-4 gap-2 text-xs font-medium text-gray-600 mb-2">
      <span>DIAMOND TYPE</span>
      <span></span>
      <span></span>
      <span>WEIGHT</span>
    </div>
    <div className="grid grid-cols-4 gap-2 text-sm text-gray-800">
      <span>IJ-SI</span>
      <span></span>
      <span></span>
      <span>{weight} ct</span>
    </div>
  </div>
);

export default ProductDetails;
