import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { emptyCart } from "../../store/reducers/cartReducer";

const SuccessPage = () => {
    const dispatch = useDispatch();

useEffect(() => {
  
    setTimeout(() => {
        window.location.href = "/"
        dispatch(emptyCart())
    }, 5000);
}, [])



  return (
    <>
      <div id="page" className="hfeed page-wrapper">
        <div id="site-main" className="site-main">
          <div id="main-content" className="main-content">
            <div id="primary" className="content-area">
              <div id="content" className="site-content" role="main">
                <div className="section-padding">
                  <div className="section-container p-l-r">
                    <div className="page-404">
                      <div className="content-page-404 flex flex-col" style={{alignItems:"center"}}>
                        
                            <img src="https://cdn2.iconfinder.com/data/icons/greenline/512/check-512.png" width={'200px'}/>
                        
                        <div className="sub-title">
                          Payment Completed ! Thank You
                        </div>
                        <div className="sub-error">
                          Confirmation will be shared to your mail
                        </div>
                        <Link className="button" to="/">
                          Explore more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* #content */}
            </div>
            {/* #primary */}
          </div>
          {/* #main-content */}
        </div>
      </div>
     
    </>
  );
};

export default SuccessPage;
